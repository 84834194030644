<template>
  <v-container fluid>
    <div class="campaign-header">
      <h1 class="campaign-title" :style="campaignColor">
        {{ campaign.title }}
      </h1>
      <router-link class="link" :to="backLink">
        <v-icon color="primary">$chevron-left</v-icon>
        {{ $lang.campaign_InfoBackLink }}
      </router-link>
    </div>
    <div class="campaign-subheader">
      <div class="campaign-info">
        <v-icon left>{{ inviteTypeMap[campaign.inviteType].icon }}</v-icon>
        {{ inviteTypeMap[campaign.inviteType].text }}
      </div>
      <div class="campaign-info">
        {{ $lang.campaign_InfoSentOn.format(toDisplayDate(campaign.sentAt)) }}
      </div>
      <div class="campaign-info">
        {{ $lang.campaign_InfoAmbassadorCount.format(ambassadorCount) }}
      </div>
      <div class="campaign-info">
        {{ $lang.campaign_InfoRecipientCount.format(invites.length) }}
      </div>
      <div class="campaign-info">
        {{ $lang.campaign_InfoCreatedBy.format(toFullName(campaign.adminID)) }}
      </div>
    </div>
    <OutreachRequestsTable :items="items" :columns="columns" :search="search" />
  </v-container>
</template>

<script>
  import OutreachRequestsTable from "./OutreachRequestsTable.vue";
  import { toFullName } from "@/shared/utils";
  import dateService from "@/services/date.service";
  import campaignColors from "@/constants/campaignColors";

  export default {
    components: { OutreachRequestsTable },
    props: {
      campaign: {
        type: Object,
        required: true,
      },
      invites: {
        type: Array,
        require: true,
      },

      prevRoute: {
        type: Object,
      },
    },
    data() {
      return {
        columns: [
          "inviterName",
          "inviteeName",
          "status",
          "accountMade",
          "actions",
          "hasFeedback",
        ],
        search: "",
        inviteTypeMap: {
          email: {
            icon: "$email-outline",
            text: this.$lang.outreachPage_InviteTypeEmail,
          },
          call: {
            icon: "$phone-outline",
            text: this.$lang.outreachPage_InviteTypeCall,
          },
          video: {
            icon: "$video-outline",
            text: this.$lang.outreachPage_InviteTypeVideo,
          },
        },
      };
    },
    computed: {
      backLink() {
        let backLink = {
          name: "OUTREACH_SCREEN",
        };
        if (
          this.prevRoute?.name === "OUTREACH_SCREEN" &&
          this.prevRoute?.query.page
        ) {
          backLink.query = this.prevRoute.query;
          backLink.hash = this.prevRoute.hash;
        }

        return backLink;
      },

      items() {
        return (
          this.invites?.map((invite) => {
            invite = {
              ...invite,
              inviteType: this.campaign.inviteType,
              sentAt: this.campaign.sentAt,
              inviterName: this.toFullName(invite.inviterID),
              inviteeName: this.toFullName(invite.invitee),
              status: !!(
                invite.inviteSent ??
                invite.hadCall ??
                invite.invitation
              ),
              accountMade: !!(
                invite.accountMade ?? invite.invitation?.acceptedUserId
              ),
              hasFeedback: !!invite.feedback?.length,
            };
            return invite;
          }) || []
        );
      },
      campaignColor() {
        let color = "transparent";

        if (this.campaign.sentAt) {
          const num = new Date(this.campaign.sentAt).getTime();
          color = campaignColors[num % campaignColors.length];
        }

        return {
          "border-color": color,
        };
      },
      ambassadorCount() {
        return new Set(
          this.items
            .filter((invite) => invite.inviterID)
            .map((invite) => invite.inviterID._id)
        ).size;
      },
    },

    methods: {
      toFullName(user) {
        user = user || {};
        return toFullName(
          user.first,
          user.last,
          this.$lang.general_DeletedUser
        );
      },
      toDisplayDate(date) {
        return dateService.toDisplayDate(date);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .campaign-header {
    display: flex;
    justify-content: space-between;
  }
  .campaign-title {
    border-left: 6px solid;
    padding: 0 16px;
    min-height: 32px;
    line-height: 32px;
  }
  .campaign-subheader {
    display: flex;
    padding: 20px 0 32px;
    border-bottom: 1px solid $grey-400;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .campaign-info {
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    border-left: 1px solid $grey-400;

    @media (max-width: $md) {
      padding: 0 10px;
    }
  }
  .campaign-info:first-child {
    padding-left: 0;
    border: none;
  }
  .link {
    display: inline-flex;
    align-items: center;
  }
</style>
