import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableHeader',{attrs:{"search":_vm.search,"headers":_vm.headers.filter(function (header) { return header.map; })},on:{"input":function($event){_vm.search = $event},"filter":_vm.filter,"export":_vm.exportCsv}}),_c(VDataTable,{staticClass:"outreach-table",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","search":_vm.search,"show-select":"","sort-by":"sentAt","sort-desc":"","options":{
      page: _vm.$route.query.page || 1,
      itemsPerPage: parseInt(_vm.$route.query.itemsPerPage, 10) || 10,
    },"hide-default-footer":!_vm.items || _vm.items.length === 0,"footer-props":{
      showFirstLastPage: true,
      firstIcon: '$chevron-double-left',
      lastIcon: '$chevron-double-right',
      prevIcon: '$chevron-left',
      nextIcon: '$chevron-right',
      'items-per-page-options': [10, 25, 50, 100, -1],
    }},on:{"input":function($event){_vm.checkedItems = $event},"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
    var item = ref.item;
return [((item.campaignId || item.id) && item.title)?_c('router-link',{staticClass:"outreach-table-campaign",style:(_vm.campaignColor(item.title && item.createdAt)),attrs:{"to":{
          name: 'Outreach Campaign',
          params: { campaignId: item.campaignId || item.id },
        }}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]}},{key:"item.inviterName",fn:function(ref){
        var item = ref.item;
return [_c('Avatar',{attrs:{"imgSrc":item.inviterID ? item.inviterID.imageURL : null,"left":""}}),(item.inviterID && item.inviterID._id)?_c('router-link',{attrs:{"to":{
          name: 'PROFILE_SCREEN',
          params: { id: item.inviterID._id },
        }}},[_vm._v(" "+_vm._s(item.inviterName)+" ")]):[_vm._v(_vm._s(_vm.$lang.general_DeletedUser))]]}},{key:"item.inviteeName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.inviteeName)+" ")]}},{key:"item.inviteType",fn:function(ref){
        var item = ref.item;
        var header = ref.header;
return [(header.map[item.inviteType])?_c('div',{staticClass:"outreach-table-type"},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(header.map[item.inviteType].icon))]),(item.message)?_c('a',{on:{"click":function($event){return _vm.openInvitationModal(item)}}},[_vm._v(" "+_vm._s(header.map[item.inviteType].text)+" "),(item.inviteType === _vm.inviteTypes.CALL)?_c(VIcon,{attrs:{"color":"primary","right":""}},[_vm._v(" $alert-circle ")]):_vm._e()],1):_c('span',{staticClass:"outreach-table-type"},[_vm._v(" "+_vm._s(header.map[item.inviteType].text)+" ")])],1):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
        var header = ref.header;
return [_c(VIcon,{attrs:{"color":header.map[item.status].color}},[_vm._v("$circle-small")]),_vm._v(" "+_vm._s(header.map[item.status].text)+" ")]}},{key:"item.accountMade",fn:function(ref){
        var item = ref.item;
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.map[item.accountMade].text)+" ")]}},{key:"item.invites",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.invites.length)+" ")]}},{key:"item.sentAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDisplayDate(item.sentAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDisplayDate(item.createdAt))+" ")]}},{key:"item.scheduledFor",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDisplayDateTimeTz(item.scheduledFor))+" ")]}},{key:"item.adminName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.adminName)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.inviterID)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","aria-label":_vm.$lang.outreachPage_ViewRequest},on:{"click":function($event){return _vm.openRequestModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$email-open")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang.outreachPage_ViewRequest))])]):[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","to":{
                name: 'Outreach Campaign',
                params: { campaignId: item.id },
                query: { copy: 1 },
              },"aria-label":_vm.$lang.outreachPage_CopyCampaign}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$file-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang.outreachPage_CopyCampaign))])]),(!item.sentAt)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","aria-label":_vm.$lang.outreachPage_DeleteCampaign},on:{"click":function($event){return _vm.showDeleteCampaignModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang.outreachPage_DeleteCampaign))])]):_vm._e()],(item.feedback)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","aria-label":_vm.$lang.outreachPage_Feedback},on:{"click":function($event){return _vm.openFeedbackModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("$comment-quote")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang.outreachPage_Feedback))])]):_vm._e()]}}])}),(_vm.feedbackModal)?_c('FeedbackModal',{attrs:{"conversationCallOrCallInvitationId":_vm.selectedItem.id,"source":_vm.inviteTypeMap[_vm.selectedItem.inviteType].text,"ambassadorName":_vm.selectedItem.inviterName,"prospectName":_vm.selectedItem.inviteeName},model:{value:(_vm.feedbackModal),callback:function ($$v) {_vm.feedbackModal=$$v},expression:"feedbackModal"}}):_vm._e(),(_vm.requestModal)?_c('p-modal',{attrs:{"size":"sm","title":_vm.$lang.outreachPage_ViewRequestModalTitle.format(
        _vm.inviteTypeMap[_vm.selectedItem.inviteType].text,
        _vm.selectedItem.inviterName
      ),"showCancelButton":false,"showSaveButton":false},model:{value:(_vm.requestModal),callback:function ($$v) {_vm.requestModal=$$v},expression:"requestModal"}},[_c('h5',{staticClass:"modal-label"},[_vm._v(_vm._s(_vm.$lang.outreachPage_ModalMessage))]),_c('div',{staticClass:"modal-body"},[_vm._v(_vm._s(_vm.selectedItem.notes))])]):_vm._e(),(_vm.invitationModal)?_c('p-modal',{attrs:{"size":"sm","title":_vm.selectedItem.inviteType === _vm.inviteTypes.CALL
        ? _vm.$lang.outreachPage_CallMessageModalTitle.format(
            _vm.selectedItem.inviterID && _vm.selectedItem.inviterID.first
              ? _vm.selectedItem.inviterID.first
              : _vm.$lang.general_DeletedUser,
            _vm.selectedItem.invitee.first || _vm.$lang.general_DeletedUser
          )
        : _vm.$lang.outreachPage_InvitationModalTitle.format(
            _vm.selectedItem.inviterID && _vm.selectedItem.inviterID.first
              ? _vm.selectedItem.inviterID.first
              : _vm.$lang.general_DeletedUser,
            _vm.inviteTypeMap[_vm.selectedItem.inviteType].text,
            _vm.selectedItem.invitee.first || _vm.$lang.general_DeletedUser
          ),"showCancelButton":false,"showSaveButton":false},model:{value:(_vm.invitationModal),callback:function ($$v) {_vm.invitationModal=$$v},expression:"invitationModal"}},[(_vm.selectedItem.submission)?_c('video',{staticClass:"modal-video",attrs:{"src":_vm.selectedItem.submission.video.mp4Url,"controls":""}}):_vm._e(),_c('h5',{staticClass:"modal-label"},[_vm._v(_vm._s(_vm.$lang.outreachPage_ModalMessage))]),_c('div',{staticClass:"modal-body"},[_vm._v(_vm._s(_vm.selectedItem.message.body))]),_c('hr'),_c('h5',{staticClass:"modal-label"},[_vm._v(_vm._s(_vm.$lang.outreachPage_ModalDateSent))]),_c('div',[_vm._v(" "+_vm._s(_vm.toDisplayDateTime(_vm.selectedItem.message.createdAt))+" ")])]):_vm._e(),(_vm.deleteCampaignModal)?_c('p-modal',{attrs:{"size":"sm","title":_vm.$lang.outreachPage_DeleteCampaignTitle,"saveButtonText":_vm.$lang.general_Yes,"cancelButtonText":_vm.$lang.general_No},on:{"save":_vm.deleteCampaign,"cancel":function($event){_vm.deleteCampaignModal = false}},model:{value:(_vm.deleteCampaignModal),callback:function ($$v) {_vm.deleteCampaignModal=$$v},expression:"deleteCampaignModal"}},[_vm._v(" "+_vm._s(_vm.$lang.outreachPage_DeleteCampaignMessage.format(_vm.selectedItem.title))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }